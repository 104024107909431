<template>
	<main>
		<div class="article">
			<img :src="item" alt="" v-for="(item,index) in photos" :key="index">
		</div>
	</main>
</template>

<script>
export default {
	props:{
		detail:{
			type:Object,
			default:{}
		}
	},
	computed:{
		photos(){
			if(!this.detail || !this.detail.photo){
				return [];
			}else{
				return this.detail.photo.split(',')
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.article{
	img{display:block;width: 100%;}
}
</style>

