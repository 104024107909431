<template>
	<main>
		<div class="detail">
			<div class="center">
				<div class="info">
					<div class="title">{{detail.title}}</div>
					<div class="tag">
						<span>{{detail.brand}}</span>
						<span>{{typeText(detail.type)}}</span>
						<template v-if="detail.tag && detail.tag.length > 0">
							<span v-for="(item,index) in detail.tag.split(',')">{{item}}</span>
						</template>
					</div>
				</div>
				<div class="content" :class="'template-'+detail.template">
					<components-one v-if="detail.template === 1" :detail="detail"/>
					<components-two v-if="detail.template === 2" :detail="detail"/>
					<components-three v-if="detail.template === 3" :detail="detail"/>
					<components-four v-if="detail.template === 4" :detail="detail"/>
					<div class="more">
						<a-link v-if="prev && prev.id" :to="'/cases/detail?cases_id='+prev.id" class="left block btn prev"><i class="iconfont icon-shangyiyehoutuifanhui"></i> {{prev.title}}</a-link>
						<a-link v-if="next && next.id" :to="'/cases/detail?cases_id='+next.id" class="right block btn next">{{next.title}} <i class="iconfont icon-xiayiyeqianjinchakangengduo"></i></a-link>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import ComponentsOne from "@/components/views/cases/detail/one.vue"
import ComponentsTwo from "@/components/views/cases/detail/two.vue"
import ComponentsThree from "@/components/views/cases/detail/three.vue"
import ComponentsFour from "@/components/views/cases/detail/four.vue"
import {site_config} from "@/config";
import {cases} from "@/api/views";
import {toError} from "@/service/views/common";
export default {
	components:{ComponentsOne,ComponentsTwo,ComponentsThree,ComponentsFour},
	data(){
		return {
			query:{},
			detail:{},
			prev:{},
			next:{}
		}
	},
	computed:{
		photos(){
			if(!this.detail || !this.detail.photo){
				return [];
			}else{
				return this.detail.photo.split(',')
			}
		}
	},
	created() {
		this.query = this.$route.query
	},
	mounted() {
		this.getCasesDetail();
	},
	methods: {
		typeText(type){
			const case_type = site_config.case_type;
			const cases = case_type.find((item,index)=>{
				return item.id === type;
			})
			return cases?.title || '';
		},
		getCasesDetail(){
			cases.get({
				cases_id:this.query.cases_id
			}).then((res)=>{
				this.detail = res.detail;
				this.prev = res.prev;
				this.next = res.next;
			}).catch((error)=>{
				toError(error)
			})
		}
	},
}
</script>

<style lang="scss" type="text/scss" scoped>
.detail{margin-top: 200px;padding-bottom: 100px;
	.center{width: 1200px;margin:0 auto;
		.info{
			.title{font-size: 30px;font-weight: bold;}
			.tag{margin-top: 20px;font-size: 14px;
				span{display: block;float: left;padding:0 0 0 15px;color:#666;}
				span::after{content:"|";display:inline-block;margin-left: 15px;}
				span:nth-child(1){padding-left: 0;}
				span:nth-last-child(1)::after{display:none;}
			}
		}
		.content{margin-top: 100px;
			.more{padding: 30px 0;overflow:hidden;
				.btn{font-size: 18px;color:#333;
					.iconfont{font-size: 20px;}
				}
				@media (any-hover: hover){
					.btn:hover{color:$main;}
				}
			}
		}
	}
}
@media screen and (max-width: 1220px) {
	.detail{margin-top: m(200px);padding-bottom: m(100px);
		.center{width:auto;padding: 0 25px;
			.info{
				.title{font-size: 24px;font-weight: bold;}
				.tag{margin-top: 14px;font-size: 12px;}
			}
			.content{margin-top:m(100px);
				.more{padding: 30px 0;overflow:hidden;
					.btn{font-size: 16px;
						.iconfont{font-size: 20px;}
					}
					@media (any-hover: hover){
						.btn:hover{color:$main;}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.detail{margin-top: x(200px);padding-bottom: x(100px);
		.center{width:auto;padding: 0 15px;
			.info{
				.title{font-size: 20px;font-weight: bold;}
				.tag{margin-top: 14px;font-size: 12px;}
			}
			.content{margin-top:m(80px);
				.more{padding: 16px 0;overflow:hidden;
					.btn{font-size: 14px;
						.iconfont{font-size: 20px;}
					}
					@media (any-hover: hover){
						.btn:hover{color:$main;}
					}
				}
			}
		}
	}
}
</style>

