<template>
	<main>
		<div class="article-box" :style="{background:(detail.bg||'#41d4fd')}">
			<div class="point">
				<div class="p" v-for="(item,index) in 10" :style="pointStyle()"></div>
			</div>
			<div class="con" v-if="photos && photos.length > 0">
				<img class="logo" alt="" v-if="detail.logo" :src="detail.logo" />
				<div class="cover">
					<div class="box" :style="{backgroundImage:'url('+photos[0]+')'}"></div>
				</div>
				<div class="title">
					<div class="t1">interface</div>
					<div class="t2">界面展示</div>
				</div>
				<div class="article">
					<img :src="item" alt="" v-for="(item,index) in photos" v-if="index > 0" :key="index">
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import {random} from "lodash";
export default {
	props:{
		detail:{
			type:Object,
			default:{}
		}
	},
	computed:{
		photos(){
			if(!this.detail || !this.detail.photo){
				return [];
			}else{
				return this.detail.photo.split(',')
			}
		}
	},
	methods: {
		pointStyle(){
			var r = random(300,700)+'px'
			return {
				width:r,
				height:r,
				top:random(-5,100)+'%',
				left:random(-5,100)+'%',
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.article-box{background: #00c8dc;position: relative;overflow: hidden;
	.point{position:absolute;top:0;left:0;width: 100%;height:100%;
		.p{position: absolute;background: rgba(255,255,255,.3);border-radius: 50%;}
	}
	.con{position: relative;padding-top: 150px;
		.logo{width:120px;height:60px;position: absolute;top:30px;left:30px; object-fit: contain;object-position: left top;}
		.cover{position: relative;width: 1000px;height:478px;background: url("~@/assets/image/cases/pc_dark.png") no-repeat center center;background-size: contain;margin:0 auto 0;
			.box{position: absolute;left:50%;top:17px;transform: translateX(-50%); width: 820px;height:400px;background-repeat: no-repeat;background-position: center center;background-size: cover;}
		}
		.title{text-align: center;color:#fff;padding-top: 100px;
			.t1{font-size: 50px;font-weight: bold;text-transform: uppercase;}
			.t2{font-size: 22px;margin-top: 15px;}
		}
		.article{width: 815px;padding: 100px 0;margin: 0 auto;
			img{display:block;width: 100%;margin: 0 auto 100px;}
		}
	}
}

@media screen and (max-width: 1220px) {
	.article-box{
		.con{padding-top:m(150px);
			.logo{width:m(120px);height:m(60px);top:m(30px);left:m(30px);}
			.cover{width:m(800px);height:m(382px);
				.box{top:m(13px);width:m(660px);height:m(322px);}
			}
			.title{padding-top:m(100px);
				.t1{font-size:m(50px);}
				.t2{font-size:m(22px);margin-top:m(15px);}
			}
			.article{width:m(660px);padding: m(100px) 0;
				img{margin: 0 auto m(100px);}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.article-box{
		.con{padding-top:x(150px);
			.logo{width:x(120px);height:x(60px);top:x(30px);left:x(30px);}
			.cover{width:x(600px);height:x(287px);
				.box{top:x(10px);width:x(500px);height:x(240px);}
			}
			.title{padding-top:x(100px);
				.t1{font-size:x(50px);}
				.t2{font-size:m(22px);margin-top:x(15px);}
			}
			.article{width:x(500px);padding: x(150px) 0;
				img{margin: 0 auto x(100px);}
			}
		}
	}
}
</style>

