<template>
	<main>
		<div class="article-box" :style="{background:(detail.bg||'#41d4fd')}">
			<div class="point">
				<div class="p" v-for="(item,index) in 10" :style="pointStyle()"></div>
			</div>
			<div class="con" v-if="photos && photos.length > 0">
				<img class="logo" alt="" v-if="detail.logo" :src="detail.logo" />
				<div class="cover">
					<div class="tit-wrap">
						<div class="tit">{{detail.title}}</div>
						<div class="desc">{{detail.description}}</div>
					</div>
					<div class="box">
						<img class="img" alt="" :src="photos[0]" />
					</div>
				</div>
				<div class="title">
					<div class="t1">interface</div>
					<div class="t2">界面展示</div>
				</div>
				<div class="article">
					<img :src="item" alt="" v-for="(item,index) in photos" v-if="index > 0" :key="index">
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import {random} from "lodash";
export default {
	props:{
		detail:{
			type:Object,
			default:{}
		}
	},
	computed:{
		photos(){
			if(!this.detail || !this.detail.photo){
				return [];
			}else{
				return this.detail.photo.split(',')
			}
		},
		cover(){
			return this.photos[0];
		},
	},
	methods: {
		pointStyle(){
			var r = random(300,700)+'px'
			return {
				width:r,
				height:r,
				top:random(-5,100)+'%',
				left:random(-5,100)+'%',
			}
		},
	},
}
</script>

<style lang="scss" type="text/scss" scoped>
.article-box{background: #00c8dc;position: relative;overflow: hidden;
	.point{position:absolute;top:0;left:0;width: 100%;height:100%;
		.p{position: absolute;background: rgba(255,255,255,.3);border-radius: 50%;}
	}
	.con{position: relative;padding-top: 150px;
		.logo{width:120px;height:60px;position: absolute;top:30px;left:30px; object-fit: contain;object-position: left top;}
		.cover{position: relative;display: flex;justify-content: center;align-items: center;
			.tit-wrap{margin-right: 100px;width: 375px;margin-top: -200px;
				.tit{font-size: 40px;color:#fff;font-weight: bold;}
				.desc{font-size: 22px;color:#fff;margin-top: 30px;}
			}
			.box{display: block; width: 375px;height:628px;position: relative;
				.img{width: 328px;height:586px;position: absolute;top:23px;left:50%;transform: translateX(-50%);}
			}
			.box::after{content: "";display: block;position: absolute;top:0;left:0;width: 100%;height:100%;background: url("~@/assets/image/cases/phone.png") no-repeat center center;background-size: 100% 100%;}
		}
		.title{text-align: center;color:#fff;padding-top: 100px;
			.t1{font-size: 50px;font-weight: bold;text-transform: uppercase;}
			.t2{font-size: 22px;margin-top: 15px;}
		}
		.article{padding: 100px;column-count: 2; column-gap: 100px;
			img{display:block;width:100%;break-inside: avoid;margin-bottom: 100px;}
		}
	}
}

@media screen and (max-width: 1220px) {
	.article-box{
		.con{padding-top:m(150px);
			.logo{width:m(120px);height:m(60px);top:m(30px);left:m(30px);}
			.cover{
				.tit-wrap{margin-right:m( 100px);width: m(375px);margin-top: m(-200px);
					.tit{font-size: m(40px);}
					.desc{font-size:m(40px);margin-top:m(30px);}
				}
				.box{ width: m(375px);height:m(628px);
					.img{width:m( 328px);height:m(586px);top:m(23px);left:50%;transform: translateX(-50%);}
				}
			}
			.title{padding-top: m(100px);
				.t1{font-size: m(50px);}
				.t2{font-size:m(22px);margin-top:m( 15px);}
			}
			.article{padding:m(100px);column-count: 2; column-gap:m( 100px);
				img{width:100%;margin-bottom:m(100px);}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.article-box{
		.con{padding-top:x(150px);
			.logo{width:x(120px);height:x(60px);top:x(30px);left:x(30px);}
			.cover{
				.tit-wrap{margin-right:x(10px);width: x(275px);margin-top: x(-200px);
					.tit{font-size: x(40px);}
					.desc{font-size:x(30px);margin-top:x(30px);}
				}
				.box{ width: x(375px);height:x(628px);
					.img{width:x( 328px);height:x(586px);top:x(23px);left:50%;transform: translateX(-50%);}
				}
			}
			.title{padding-top: x(100px);
				.t1{font-size: x(50px);}
				.t2{font-size:m(22px);margin-top:m( 15px);}
			}
			.article{padding:x(100px);column-count: 1; column-gap:x( 100px);
				img{width:100%;margin-bottom:x(100px);}
			}
		}
	}
}
</style>

